import React from 'react';
import classnames from 'classnames';

import './main-content.css';

export default ({ className, children }) => (
  <div className={classnames('main-content', className)}>
    {children}
  </div>
);
