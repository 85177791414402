import React, { useEffect } from 'react';

import Layout from 'components/layout';
import MainContent from 'components/MainContent';

import styles from './registry-page.module.scss';

export default () => {
  useEffect(() => {
    /* eslint-disable */
    !function(e,t,n){
      var s,a = e.getElementsByTagName(t)[0];
      console.log({s})
      console.log({a})
      console.log({e})
      e.getElementById(n) || (s=e.createElement(t),s.id=n,s.async=!0,s.src="https://widget.zola.com/js/widget.js",a.parentNode.insertBefore(s,a))
    }(document,"script","zola-wjs");
    /*eslint-disable*/
  });

  return (
    <Layout>
      <MainContent className={styles.registryConstruction}>
      <a
        className="zola-registry-embed"
        href="https://www.zola.com/registry/danielandsonya2019"
        data-registry-key="danielandsonya2019"
      >
        Our Registry
      </a>
      <script />
      </MainContent>
    </Layout>
  );
}
